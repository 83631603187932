<template>
  <el-divider
    v-bind="{ ...$attrs, ...elProps }"
    :class="[
      'ui-divider',
      `ui-divider--${props.color}`,
      {
        'ui-divider--rounded': props.rounded,
      },
    ]"
    :style="{
      borderWidth: props.size + 'px',
      margin:
        props.direction === 'horizontal'
          ? `${props.spacing}px 0`
          : `0 ${props.spacing}px`,
    }"
  >
    <slot name="default"></slot>
  </el-divider>
</template>
<script setup lang="ts">
import { dividerProps } from 'element-plus'

export type DividerColor =
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'textTertiary'
  | 'sell'
  | 'buy'
  | 'tag'
  | 'error'
  | 'success'

const props = defineProps({
  ...dividerProps,
  /**
   * @description The color of the divider
   * @default textTertiary
   */
  color: {
    type: String as PropType<DividerColor>,
    default: 'textTertiary',
  },
  /**
   * @description Spacing between the divider and the surrounding content, px number value for margin
   * @default 24
   */
  spacing: {
    type: Number,
    default: 24,
  },
  /**
   * @description Width or height of the divider, number in px
   * @default 1
   */
  size: {
    type: Number,
    default: 1,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
})
const elProps = computed(() => {
  const { color, spacing, size, rounded, ...rest } = props
  return rest
})
</script>
<style lang="postcss" scoped>
.ui-divider.el-divider {
  &.ui-divider--primary {
    border-color: var(--brand-color-primary);
  }
  &.ui-divider--secondary {
    border-color: var(--brand-color-secondary);
  }
  &.ui-divider--textPrimary {
    border-color: var(--brand-color-text-primary);
  }
  &.ui-divider--textSecondary {
    border-color: var(--brand-color-text-secondary);
  }
  &.ui-divider--textTertiary {
    border-color: var(--brand-color-text-tertiary);
  }
  &.ui-divider--sell {
    border-color: var(--brand-color-sell);
  }
  &.ui-divider--buy {
    border-color: var(--brand-color-buy);
  }
  &.ui-divider--tag {
    border-color: var(--brand-color-tag);
  }
  &.ui-divider--error {
    border-color: var(--brand-color-error);
  }
  &.ui-divider--success {
    border-color: var(--brand-color-success);
  }
  &.ui-divider--rounded {
    border-radius: 9999px;
  }
}
</style>
